import React, { useEffect } from "react"
import { Button, Box, Container, Flex, Styled, useColorMode } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Layout from "@layouts/default"
import GoogleMap from "@components/GoogleMap"
import Link from "@common/Link"
import SEO from "@components/seo"
import Prelegent from "@components/Prelegent"

import PlaceIcon from "@assets/placeBig.svg"
import PriceIcon from "@assets/price.svg"
import ClockIcon from "@assets/clock.svg"
import SettingsIcon from "@assets/settings.svg"

import prelegent1 from "../images/prelegents/john-mark-arnold-xbBaOa_dd5I-unsplash.png"
import prelegent2 from "../images/prelegents/jurica-koletic-7YVZYZeITc8-unsplash.png"
import prelegent3 from "../images/prelegents/mihai-stefan-0_G9UHXy39c-unsplash.png"
import SocialMediaButtons from "@components/SocialMediaButtons"
const prelegents = [
  {
    name: "Jan Kowalski",
    organization: "Codete GmbH",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    img: prelegent3,
  },
  {
    name: "Marcin Nowak",
    organization: "Codete GmbH",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    img: prelegent2,
  },
  {
    name: "Igor Maślak",
    organization: "Codete GmbH",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    img: prelegent1,
  },
]

export default () => {
  const [colorMode, setColorMode] = useColorMode()

  useEffect(() => setColorMode("light"), [colorMode, setColorMode])

  const data = useStaticQuery(graphql`
    query {
      event: file(
        relativePath: { eq: "events/headway/headway-F2KRf_QfCqw-unsplash.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <BackgroundImage fluid={data.event.childImageSharp.fluid}>
        <Flex variant="layout.event.container">
          <Container>
            <Flex
              as="section"
              variant="layout.flexColumn"
              sx={{ position: "relative" }}
            >
              <Link to="/workshops" variant="backTo">
                Wróć do Workshops
              </Link>
              <Flex variant="layout.event.header">
                <Styled.h1>Codete Workshops w Krakowie</Styled.h1>
                <Flex sx={{ flex: "1 0 100%" }}>
                  <Flex
                    sx={{
                      color: "white",
                      flex: "1 0 50%",
                      flexDirection: "column",
                      fontFamily: "heading",
                      fontSize: "30px",
                      ml: "80px",
                    }}
                  >
                    <Flex sx={{ alignItems: "center", mb: "32px" }}>
                      <PlaceIcon />
                      <Box sx={{ml: '42px'}}>Na Zjeździe 11, Kraków</Box>
                    </Flex>
                    <Flex sx={{ alignItems: "center", mb: "32px" }}>
                      <ClockIcon />
                      <Box sx={{ml: '42px'}}>18:30</Box>
                    </Flex>
                    <Flex sx={{ alignItems: "center", mb: "32px" }}>
                      <SettingsIcon />
                      <Box sx={{ml: '42px'}}>AWS - podstawy Big Data</Box>
                    </Flex>
                    <Flex sx={{ alignItems: "center", mb: "32px" }}>
                      <PriceIcon />
                      <Box sx={{ml: '42px'}}>25zł</Box>
                    </Flex>
                  </Flex>
                  <Flex
                    sx={{
                      flex: "1 0 50%",
                      flexDirection: "column",
                      fontFamily: "heading",
                    }}
                  >
                    {/* <Box>25/04/2020</Box>
                    <Box>zapisz w kalendarzu google</Box>
                    <Box>zarejestruj się</Box> */}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Container>
        </Flex>
      </BackgroundImage>
      <Flex>
        <Container>
          <Flex as="section" variant="layout.flexColumn">
            <Flex variant="layout.event.content">
              <Flex variant="layout.event.details">
                <Styled.h4>Informacje o Wydarzeniu:</Styled.h4>
                <Styled.h5>Agenda:</Styled.h5>
                {/* <Box>Na Zjeździe 11, Kraków</Box>
                <Box>18:30</Box>
                <Box>AWS - podstawy Big Data</Box>
                <Box>25zł</Box> */}
              </Flex>
              <Flex variant="layout.event.links">
                <Styled.h4>
                  Sprawdź wydadzenie na stronie organizatora:
                </Styled.h4>
                <Button
                  sx={{
                    mt: "58px",
                    width: `338px`,
                    height: `93px`,
                    background: `#0D1537 0% 0% no-repeat padding-box`,
                    borderRadius: `47px`,
                    textAlign: `center`,
                    fontWeight: 600,
                    fontSize: `20px`,
                    fontFamily: "heading",
                    lineHeight: `40px`,
                    letterSpacing: `1.6px`,
                    color: `#FFFFFF`,
                  }}
                >
                  Link do wydarzenia
                </Button>
                <Flex sx={{ py: "50px", flex: 1, justifyContent: "center" }}>
                  <SocialMediaButtons />
                </Flex>
                <Button
                  sx={{
                    width: `338px`,
                    height: `142px`,
                    background: `#FBB03B 0% 0% no-repeat padding-box`,
                    borderRadius: `47px`,
                    textAlign: `center`,
                    fontWeight: 600,
                    fontSize: `20px`,
                    fontFamily: "heading",
                    lineHeight: `40px`,
                    letterSpacing: `1.6px`,
                    color: `#0D1537`,
                  }}
                >
                  Udostępnij link do wydarzenia
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Flex as="section" variant="layout.flexColumn">
            <Flex variant="layout.event.prelegents">
              <Styled.h4>Prelegenci:</Styled.h4>
              <Flex sx={{ justifyContent: "space-between" }}>
                {prelegents.map(data => (
                  <Prelegent {...data} />
                ))}
              </Flex>
            </Flex>
          </Flex>
          <Flex as="section">
            <Flex variant="layout.event.content">
              <Styled.h4>Dojazd</Styled.h4>
              <Flex>
                <GoogleMap />
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Flex>
    </Layout>
  )
}
