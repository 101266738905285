import React from "react"
import { Flex, Image, Styled } from "theme-ui"

export default ({ img, name, organization, description }) => (
  <figure>
    <Image alt={name} src={img} />
    <figcaption
      style={{ alignItems: "center", display: "flex", flexDirection: "column", fontFamily: "Work Sans" }}
    >
      <Styled.h5
        style={{
          flex: "1",
          marginTop: "28px",
          marginBottom: '14px',
          height: `35px`,
          textAlign: `center`,
          fontWeight: 600,
          fontFamily: "Work Sans",
          fontSize: "30px",
          lineHeight: "35px",
          letterSpacing: `2.4px`,
          color: `#FBB03B`,
        }}
      >
        {name}
      </Styled.h5>
      <strong style={{ color: '#0D1537'}}>{organization}</strong>
      <Flex sx={{
          color: '#BCBCBC',
          fontSize: '12px',
          textAlign: 'center',
          mt: '24px'
      }}>{description}</Flex>
    </figcaption>
  </figure>
)
